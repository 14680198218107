import React from 'react'
import { logoData } from './constants'

export default function InjuryNews({ data, customHeading }) {
    return (
        <>
            <div id="injury" className="my-4 md:my-7 rounded-[14px] border-[#DAE6F8] border bg-white">
                <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold border-[#DAE6F8] border-b">
                    <div className="flex items-center gap-2 md:justify-start justify-center">
                        <img src={logoData.injury.logo} alt={logoData.injury.heading} className="md:w-5 md:h-6 w-4 h-4" />
                        <h2 className='text-base md:text-2xl'>{customHeading || logoData.injury.heading}</h2>
                    </div>
                </div>
                <p className="md:p-6 p-4 md:text-base text-xs text-justify">
                    {data}
                </p>
            </div>
        </>
    )
}
