import React from 'react'
import { logoData } from './constants'

export default function AvoidablePlayers({ data, customHeading }) {
    return (
        <>
            <div id="avoidable-players" className="mt-4 md:mt-7 md:rounded-[14px] border-[#DAE6F8] border bg-white rounded-b-2xl">
                <div className="bg-[#E8F1FF] md:rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                    <div className="flex items-center gap-2 md:justify-start justify-center">
                        <img src={logoData.avoidablePlayers.logo} alt={logoData.avoidablePlayers.heading} className="w-5 h-6" />
                        <h2 className='text-base md:text-2xl'>{customHeading || logoData.avoidablePlayers.heading}</h2>
                    </div>
                </div>
                <p className="md:p-6 p-4 md:text-base text-xs text-justify">
                    {data}
                </p>
            </div>
        </>
    )
}
