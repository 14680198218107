import React from 'react'
import { logoData } from './constants'
import { UpdatedAtText } from './Teams'

export default function CaptainViceCaptain({ data, updatedAt, customHeading }) {
    return (
        <>
            <div id="captain-vice-captain" className="my-4 md:my-7 rounded-[14px] border-[#DAE6F8] border bg-white rounded-b-2xl">
                <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                    <div className="flex items-center gap-2 md:justify-start justify-center">
                        <img src={logoData.captainViceCaptain.logo} alt={logoData.captainViceCaptain.heading} className="md:w-5 md:h-6 w-4 h-4" />
                        <h2 className='text-base md:text-2xl'>{customHeading || logoData.captainViceCaptain.heading}</h2>
                    </div>
                    <p className="text-[10px] text-[#4F4F4F] font-normal md:text-start text-center md:block hidden">Predicted Playing XI {updatedAt && <> • <UpdatedAtText date={updatedAt} /></>}</p>
                </div>
                <div className="md:px-8 p-4 md:py-6 border-t border-[#DAE6F8]">
                    <div className="">
                        <p className="md:text-2xl text-xs italic text-justify font-semibold">Captain</p>
                        <p className="md:pt-6 pt-2 md:text-base text-xs text-justify">{data?.c}</p>
                    </div>
                </div>
                <div className="p-4 md:px-8 md:py-6 border-t border-[#DAE6F8]">
                    <div className="">
                        <p className="md:text-2xl text-xs  italic text-justify font-semibold">Vice Captain</p>
                        <p className="md:pt-6 pt-2 md:text-base text-xs text-justify">{data?.vc}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
