import React from 'react'
import { logoData } from './constants'
import { MatchesCarousel } from './MatchCarousel'

export default function TeamForm({ data:teamForm, customHeading }) {
    return (
        <>
            <div id="team-form" className="my-4 md:my-7 md:rounded-[14px] rounded-t-2xl border-[#DAE6F8] border">
                <div className="md:bg-[#E8F1FF] bg-[#FFFFFF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                    <div className="md:flex items-center gap-2 md:justify-start justify-center">
                        <img src={logoData.teamForm.logo} alt={logoData.teamForm.heading} className="w-5 h-6 md:block hidden" />
                        <h2 className='text-base md:text-2xl text-center'>{customHeading || logoData.teamForm.heading}</h2>
                        <p className="text-[10px] text-[#4F4F4F] font-normal md:text-start text-center">(Recent 5 Matches, Latest First)</p>
                    </div>
                </div>
                <MatchesCarousel data={teamForm} />
            </div>
        </>
    )
}
