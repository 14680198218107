import React from 'react'
import { logoData } from './constants'

const Pitch = ({ data, customHeading }) => {
    if (!data) {
        return null
    }
    return (
        <div>
            <div id="pitch-report" className="my-4 md:my-7 rounded-[14px] border-[#DAE6F8] border bg-white">
                <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                    <div className="flex items-center gap-2 md:justify-start justify-center">
                        <img src={logoData.pitchReport.logo} alt={logoData.pitchReport.heading} className="md:w-5 md:h-6 w-4 h-4" />
                        <h2 className='text-base md:text-2xl'>{customHeading || logoData.pitchReport.heading}</h2>
                    </div>
                </div>
                <div className="md:grid grid-cols-2">
                    <div>
                        <div className="md:text-[16px] text-[12px] md:grid md:grid-cols-2 p-4 border-y border-[#DAE6F8] leading-4 flex justify-between">
                            <p>Pitch:</p>
                            <p className={`font-bold ${!data?.pitch_condition ? "opacity-0" : null} min-h-4 min-w-4`}>{data?.pitch_condition || "0"}</p>
                        </div>
                        <div className="md:text-[16px] text-[12px] md:grid md:grid-cols-2 p-4 border-[#DAE6F8] leading-4 flex justify-between">
                            <p>Batting Condition:</p>
                            <p className={`font-bold ${!data?.batting_condition ? "opacity-0" : null} min-h-4 min-w-4`}>{data?.batting_condition || "0"}</p>
                        </div>
                    </div>
                    <div>
                        <div className="md:text-[16px] text-[12px] md:grid md:grid-cols-2 p-4 border-y md:border-l border-[#DAE6F8] leading-4 flex justify-between">
                            <p>Pace Bowling:</p>
                            <p className={`font-bold ${!data?.pace_bowling_condition ? "opacity-0" : null} min-h-4 min-w-4`}>{data?.pace_bowling_condition || "0"}</p>
                        </div>
                        <div className="md:text-[16px] text-[12px] md:grid md:grid-cols-2 md:border-l p-4 border-[#DAE6F8] leading-4 flex justify-between">
                            <p>Spin Bowling:</p>
                            <p className={`font-bold ${!data?.spine_bowling_condition ? "opacity-0" : null} min-h-4 min-w-4`}>{data?.spine_bowling_condition || "0"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pitch
