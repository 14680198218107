import React from 'react'
import { getAssetUrl, doScrollToTop } from '../../../../helpers/general';
import { logoData } from './constants';
import { Link } from 'react-router-dom';

const matchlogo = getAssetUrl("match.svg");
const serieslogo = getAssetUrl("series.svg");
const datelogo = getAssetUrl("date.svg");

const MatchInfo = ({ data, customHeading }) => {
    return (
        <div>
            <div id="match-info" className="my-4 md:my-7 rounded-[14px] border-[#DAE6F8] border">
                <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base border-[#DAE6F8] border-b leading-6 p-4 font-bold">
                    <div className="flex items-center gap-1 md:justify-start justify-center ">
                        <img src={logoData.matchInfo.logo} alt={logoData.matchInfo.heading} className="md:w-5 md:h-6 w-4 h-4" />
                        <h2 className='text-base md:text-2xl'>{customHeading || logoData.matchInfo.heading}</h2>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-5 px-4 md:py-3 py-2 border-b border-[#DAE6F8] md:text-[16px] text-[10px] flex items-center justify-between">
                    <div className="flex items-center col-span-2 gap-1.5">
                    <img src={matchlogo} alt={matchlogo} className="md:w-8 md:h-8 w-6 h-6" />
                    <span className="opacity-50">Match:</span>
                    </div>
                    <p className="md:col-span-3 md:font-semibold font-medium md:text-left text-right md:max-w-full max-w-[240px]">
                        <Link to={`/series/series-1/schedule/${data?.slug}/matchinfo`} onClick={doScrollToTop}>
                        {data?.title || "-"}
                        </Link>
                    </p>
                </div>
                <div className="md:grid md:grid-cols-5 px-4 md:py-3 py-2 border-b border-[#DAE6F8] md:text-[16px] text-[10px] flex items-center justify-between">
                    <div className="flex items-center col-span-2 gap-1.5">
                    <img src={serieslogo} alt={serieslogo} className="md:w-8 md:h-8 w-6 h-6" />
                    <span className="opacity-50">Series:</span>
                    </div>
                    <p className="md:col-span-3 md:font-semibold font-medium md:text-left text-right md:max-w-full max-w-[240px]">
                       {data?.tournament_name || '-'}</p>
                </div>
                <div className="md:grid md:grid-cols-5 px-4 md:py-3 py-2 md:text-[16px] text-[10px] flex items-center justify-between">
                    <div className="flex items-center col-span-2 gap-1.5">
                        <img src={datelogo} alt={datelogo} className="md:w-8 md:h-8 w-6 h-6" />
                        <span className="opacity-50">Date & Time:</span>
                    </div>
                    <p className={`md:col-span-3 md:font-semibold font-medium md:text-left text-right`}>{data?.date_start || "-"}</p>
                </div>
            </div>
        </div>
    )
}

export default MatchInfo;
