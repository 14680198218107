import React from 'react'
import { logoData } from './constants'
import SeriesStatsCommon from '../../Home/common/SeriesStats'

export default function PlayerStatsInCurrentSeries({ seriesId, customHeading }) {
    if (!seriesId) {
        return null;
    }
    return (
        <>
            <div id="player-stats" className="md:mt-7 mt-4 md:mb-7 md:rounded-[14px] border-[#DAE6F8] border bg-white rounded-2xl">
                <div className="bg-[#E8F1FF] md:rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold md:block hidden">
                <h2 className='text-base md:text-2xl'>{customHeading || logoData.playerStats.heading}</h2>
                </div>
                <div className="md:rounded-t-[14px] text-base leading-6 py-2 font-bold md:hidden">
                    <div className="text-center">
                        Players Stats
                    </div>
                    <p className="text-[10px] text-[#4F4F4F] font-normal text-center">in current series</p>
                </div>
                <div className=''>
                    <SeriesStatsCommon seriesId={seriesId} type="fantasy" />
                </div>
            </div>
        </>
    )
}
