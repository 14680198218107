import React from "react";
import { TableContentsData } from "./constants";
import Teamform from "./MatchInfo";
import Teams from "./Teams";
import Pitch from "./Pitchreport";
import Weather from "./Weather";
import TeamForm from "./TeamForm";
import CaptainViceCaptain from "./CaptainViceCaptain";
import AvoidablePlayers from "./AvoidablePlayers";
import InjuryNews from "./InjuryNews";
import PlayerStatsInCurrentSeries from "./PlayerStatsInCurrentSeries";
import CustomBlock from "./CustomBlock";

const NewsMatchInfo = ({ newsObj }) => {
  const {
    matchInfo,
    teams,
    injuryNews,
    teamForm,
    pitch,
    c_vc,
    weather,
    avoidablePlayers,
    updatedAt
  } = newsObj.match || {};

  const customBlocks = newsObj?.info?.customBlocks;

  if (!customBlocks) {
    return (
      <div className="">
        {/* Match Info */}
        {matchInfo && <div className="bg-white">
          <Teamform data={matchInfo} />
        </div>}

        {/* pitch report */}
        {pitch && <Pitch data={pitch} />}

        {/* Weather Report */}
        {weather && <Weather data={weather} />}

        {/* team form */}
        {teamForm && <TeamForm data={teamForm} />}

        {/* Captain & Vice Captain */}
        {
          (c_vc?.c || c_vc?.vc) &&
          <CaptainViceCaptain data={c_vc} updatedAt={updatedAt} />
        }

        {/* Avoidable Players */}
        {
          avoidablePlayers &&
          <AvoidablePlayers data={avoidablePlayers} />
        }

        {/* Teams */}
        {teams && <Teams data={teams} updatedAt={updatedAt} />}

        {/* Injury */}
        {
          injuryNews &&
          <InjuryNews data={injuryNews} />
        }

        {/* Players Stats in current series */}
        {newsObj?.cid && <PlayerStatsInCurrentSeries seriesId={newsObj?.cid} />}
      </div>
    );
  }

  if (Array.isArray(customBlocks) === false) return null

  return (
    <>
      {customBlocks
        .sort((a, b) => a.order - b.order)
        .map((block, index) => {
          const matchedContent = TableContentsData.find(({ id }) => id === block.id);
          if (matchedContent) {
            switch (matchedContent.id) {
              case "match-info":
                return matchInfo && <div className="bg-white rounded-[14px]">
                  <Teamform data={matchInfo} customHeading={block?.heading} />
                </div>

              case "pitch-report":
                return pitch && <Pitch data={pitch} customHeading={block?.heading} />

              case "weather-report":
                return weather && <Weather data={weather} customHeading={block?.heading} />

              case "team-form":
                return teamForm && <TeamForm data={teamForm} customHeading={block?.heading} />

              case "captain-vice-captain":
                return c_vc && <CaptainViceCaptain data={c_vc} updatedAt={updatedAt} customHeading={block?.heading} />

              case "avoidable-players":
                return avoidablePlayers && <AvoidablePlayers data={avoidablePlayers} customHeading={block?.heading} />

              case "match-teams":
                return <Teams data={teams} updatedAt={updatedAt} customHeading={block?.heading} />

              case "injury-news":
                return injuryNews && <InjuryNews data={injuryNews} customHeading={block?.heading} />

              case "injury":
                return injuryNews && <InjuryNews data={injuryNews} customHeading={block?.heading} />

              case "players-stats-in-current-series":
                return <PlayerStatsInCurrentSeries seriesId={newsObj?.cid} customHeading={block?.heading} />

              case "player-stats":
                return <PlayerStatsInCurrentSeries seriesId={newsObj?.cid} customHeading={block?.heading} />

              default:
                return null;
            }
          }
          else {
            return block && <CustomBlock key={index + "_" + block?.heading} data={block} />
          }
        })
      }
    </>
  );
};

export default NewsMatchInfo;
